import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "effectmodule-懒加载异步加载"
    }}><strong parentName="h1">{`EffectModule`}</strong>{` 懒加载/异步加载`}</h1>
    <p>{`如果你想做代码切分，或者在 App 启动之后再加载 `}<strong parentName="p">{`EffectModule`}</strong>{`，你可以直接动态加载这部分代码:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-dynamic-import-l9uks"
      }}>{`Dynamic import example`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-dynamic-import-l9uks?fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.tsx&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-dynamic-import-l9uks" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      